import { useEventEmitter } from "./useEventEmitter";
import { useEffect } from "react";

export function useEventListener(
    eventName: string,
    handler: (...args: any[]) => void
) {
    const eventEmitter = useEventEmitter();

    useEffect(() => {
        eventEmitter.addListener(eventName, handler);
        return () => {
            eventEmitter.removeListener(eventName, handler);
        };
    }, [eventEmitter, eventName, handler]);
}
