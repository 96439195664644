import React from "react";
import { ApplicationNotificationForm } from "./ApplicationNotificationForm";
import i18n from "../../constants/i18n";
import ShowIf from "../../components/ShowIf/ShowIfView";
import { useHasOneOfRoles } from "../../core/hooks/useHasOneOfRoles";
import roles from "../../constants/roles";
import { useFetchApplicationNotification } from "./hooks/useFetchApplicationNotification";

export function ApplicationNotificationEditPage() {
    const { isLoading, data } = useFetchApplicationNotification(false);

    const canEditNotification = useHasOneOfRoles([roles.admin]);

    return (
        <div className="container">
            <ShowIf condition={isLoading}>
                <div className={`loader`} />;
            </ShowIf>
            <ShowIf condition={!!data}>
                <ShowIf condition={canEditNotification}>
                    <div className="field">
                        <h2>{i18n.globalNotification.title}</h2>
                        <p>{i18n.globalNotification.summary}</p>
                    </div>
                    <div className="field">
                        <ApplicationNotificationForm notification={data} />
                    </div>
                </ShowIf>
            </ShowIf>
        </div>
    );
}
