import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter, Switch } from "react-router-dom";
import "tippy.js/dist/tippy.css";
import "react-image-crop/dist/ReactCrop.css";
import * as Sentry from "@sentry/browser";

import "./styles/app.scss";

import i18n from "./constants/i18n";
import { isAuthenticatedSelector } from "./selectors/auth";

import PublicRoutes from "./routing/PublicRoutes";
import PrivateRoutes from "./routing/PrivateRoutes";
import PrivateRoute from "./routing/PrivateRoute";
import Navbar from "./components/Navbar";

import routeNames from "./constants/routeNames";
import { fetchUser } from "./actions/user";
import { logout } from "./actions/auth";
import { showToast } from "./actions/toasts";
import { extractApiErrorMessage } from "./helpers";
import roles from "./constants/roles";
import { fetchUserAssignedCrematory } from "./actions/crematories";
import { fetchUserAssignedCeremonies } from "./actions/ceremonies";
import Modals from "./components/Modals";
import Toasts from "./components/Toasts";
import SVGLinearGradientBlue from "./components/SVGLinearGradientBlue";
import { EventContextProvider } from "./core/events/EventContextProvider";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasLoadedBaseData: false
        };
    }

    componentDidMount() {
        this.loadUserProfile();
    }

    loadUserProfile() {
        if (this.props.isAuthenticated) {
            this.props
                .dispatch(fetchUser())
                .then(async user => {
                    if (!user) {
                        return;
                    }

                    if (!user.emailVerifiedAt) {
                        this.props.history.push(routeNames.verifyEmail);
                        return;
                    }

                    if (user.roles.includes(roles.crematory)) {
                        await this.props.dispatch(fetchUserAssignedCrematory());
                    }

                    if (user.roles.includes(roles.endUser)) {
                        await this.props.dispatch(
                            fetchUserAssignedCeremonies()
                        );
                    }

                    return Promise.resolve();
                })
                .then(() => {
                    this.setState({ hasLoadedBaseData: true });

                    if (
                        !this.props.location.pathname.includes(routeNames.app)
                    ) {
                        this.props.history.push(routeNames.app);
                    }
                })
                .catch(e => {
                    Sentry.captureException(e);
                    console.error(e);
                    this.props.dispatch(
                        showToast({
                            body:
                                extractApiErrorMessage(e) ||
                                i18n.generic.failedToLoadData,
                            title: "Error",
                            themeClass: "is-danger"
                        })
                    );

                    this.props.dispatch(logout());
                });
        } else {
            this.setState({ hasLoadedBaseData: true });
        }
    }

    render() {
        if (!this.state.hasLoadedBaseData) {
            return (
                <div className="hero is-fullheight">
                    <div className="hero-head">
                        <Navbar />
                    </div>
                    <div className="hero-body">
                        <div className="loader" />
                    </div>
                </div>
            );
        }

        return (
            <>
                <EventContextProvider>
                    <Switch>
                        <PrivateRoute
                            path={routeNames.app}
                            component={PrivateRoutes}
                        />
                        <Route path="/" render={() => <PublicRoutes />} />
                    </Switch>
                    <Modals />
                    <Toasts />
                    <SVGLinearGradientBlue />
                </EventContextProvider>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: isAuthenticatedSelector(state)
    };
};

export default withRouter(connect(mapStateToProps)(App));
