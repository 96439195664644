import React from "react";
import { useFetchApplicationNotification } from "./hooks/useFetchApplicationNotification";
import ShowIf from "../../components/ShowIf/ShowIfView";
import styles from "./ApplicationNotificationBar.module.scss";
import { useEventListener } from "../../core/events/useEventListener";
import { LocalEvents } from "../../core/events/LocalEvents";

export function ApplicationNotificationBar() {
    const { data, refetch } = useFetchApplicationNotification(true);

    useEventListener(LocalEvents.APPLICATION_NOTIFICATION_CHANGED, () => {
        refetch();
    });

    return (
        <ShowIf condition={!!data}>
            <ShowIf condition={data?.enabled}>
                <div
                    className={`has-background-warning has-text-centered ${styles.applicationNotification}`}
                >
                    {data?.message}
                </div>
            </ShowIf>
        </ShowIf>
    );
}
