import {
    transformBlockResponse,
    transformBlockRequest,
    transformReorderBlockResponse
} from "./transformers/blockTransformer";
import {
    transformCeremonyResponse,
    transformCeremonyRequest
} from "./transformers/ceremonyTransformer";
import { baseURL, uploadURL } from "../constants/index";
import { getAuthToken } from "../helpers/tokenStorage";

export default function createApiService(axios) {
    const itemsPerPage = 5;
    let cancelCrematorySearch;
    let cancelCeremonySearch;
    let cancelMusicLibrarySearch;
    let cancelUserSearch;
    let cancelOrganizationSearch;

    return {
        // *** Auth-related endpoints ***
        login: credentials => axios.post("/api/auth/login", credentials),
        createUser: credentials => axios.post("/api/auth/users", credentials),
        inviteAdmin: details => axios.post("/api/auth/users/invite", details),
        deleteUser: id => axios.delete(`/api/auth/users/${id}`),
        forgotPassword: email => axios.post("/api/auth/forgot/password", email),
        resetPassword: (token, values) =>
            axios.post(`/api/auth/password/reset/${token}`, values),
        updatePassword: values =>
            axios.put(`/api/auth/password/update`, values),

        // *** User-related endpoints ***
        getUserProfile: () => axios.get("/api/auth/user"),
        getUserProfileById: userID => axios.get(`/api/auth/users/${userID}`),
        getAdminProfiles: () => axios.get("/api/auth/users/roles/admin"),
        getCrematoryAdminProfiles: () =>
            axios.get("/api/auth/users/roles/locations"),
        getTotalCrematoryCount: () => axios.get("/api/auth/locations/count"),
        getTotalDataUsage: () =>
            axios.get("/api/auth/users/locations/data-usage"),
        getUsersPaginated: (pageNumber = 1) =>
            axios.get(
                `/api/auth/users?page=${pageNumber}&page_size=${itemsPerPage}`
            ),
        searchUser: (query, page) => {
            page = Math.max(1, isNaN(parseInt(page)) ? 1 : parseInt(page));
            if (cancelUserSearch) {
                cancelUserSearch();
            }

            if (query === "") {
                return axios.get(
                    `/api/auth/users?page=${page}&page_size=${itemsPerPage}`,
                    {
                        cancelToken: new axios.CancelToken(function executor(
                            c
                        ) {
                            cancelUserSearch = c;
                        })
                    }
                );
            }

            return axios.get(
                `/api/auth/users/search/${encodeURIComponent(
                    query
                )}?page=${page}&page_size=${itemsPerPage}`,
                {
                    cancelToken: new axios.CancelToken(function executor(c) {
                        cancelUserSearch = c;
                    })
                }
            );
        },
        impersonateUser: userId =>
            axios.post(`/api/auth/users/impersonate/take/${userId}`),
        impersonateStop: () => axios.put(`/api/auth/users/impersonate/stop`),
        getOrganizationsPaginated: (pageNumber = 1) =>
            axios.get(
                `/api/auth/organizations?page=${pageNumber}&page_size=${itemsPerPage}`
            ),
        searchOrganization: (query, page) => {
            page = Math.max(1, isNaN(parseInt(page)) ? 1 : parseInt(page));
            if (cancelOrganizationSearch) {
                cancelOrganizationSearch();
            }

            if (query === "") {
                return axios.get(
                    `/api/auth/organizations?page=${page}&page_size=${itemsPerPage}`,
                    {
                        cancelToken: new axios.CancelToken(function executor(
                            c
                        ) {
                            cancelOrganizationSearch = c;
                        })
                    }
                );
            }

            return axios.get(
                `/api/auth/organizations/search/${encodeURIComponent(
                    query
                )}?page=${page}&page_size=${itemsPerPage}`,
                {
                    cancelToken: new axios.CancelToken(function executor(c) {
                        cancelOrganizationSearch = c;
                    })
                }
            );
        },
        getOrganizationById: organizationId =>
            axios.get(`/api/auth/organizations/${organizationId}`),
        postOrganization: values => {
            const body = new FormData();
            Object.keys(values).forEach(key => {
                body.append(key, values[key]);
            });

            return axios.post("/api/auth/organizations", body, {});
        },
        putOrganization: (organizationID, updatedFields) => {
            return axios.put(
                `/api/auth/organizations/${organizationID}`,
                updatedFields
            );
        },
        deleteOrganization: organizationID => {
            return axios.delete(`/api/auth/organizations/${organizationID}`);
        },
        getCrematoriesByOrganizationUserId: (organizationID, userID) => {
            // Set the pagesize to 100 for now. It's for dropdown box. If there are organizations with too much locations
            // then consider using an autocomplete solution.
            return axios.get(
                `/api/auth/organizations/${organizationID}/users/${userID}/locations?page=1&page_size=100`
            );
        },
        getLocationsByOrganizationId: organizationID => {
            return axios.get(
                `/api/auth/organizations/${organizationID}/locations`
            );
        },
        getOrganizationUsers: organizationID => {
            return axios.get(`/api/auth/organizations/${organizationID}/users`);
        },
        // *** Crematory-related endpoints ***
        searchCrematory: query => {
            if (cancelCrematorySearch) {
                cancelCrematorySearch();
            }

            if (query === "") {
                return axios.get(
                    `/api/auth/locations?page_size=${itemsPerPage}`,
                    {
                        cancelToken: new axios.CancelToken(function executor(
                            c
                        ) {
                            cancelCrematorySearch = c;
                        })
                    }
                );
            }

            return axios.get(
                `/api/auth/locations/search/${encodeURIComponent(
                    query
                )}?page_size=${itemsPerPage}`,
                {
                    cancelToken: new axios.CancelToken(function executor(c) {
                        cancelCrematorySearch = c;
                    })
                }
            );
        },
        getAllLocationsForUserManagement: (
            pageNumber = 1,
            itemsPerPage = 500
        ) =>
            axios.get(
                `/api/auth/locations?page=${pageNumber}&page_size=${itemsPerPage}`
            ),
        getCrematoriesPaginated: (pageNumber = 1) =>
            axios.get(
                `/api/auth/locations?page=${pageNumber}&page_size=${itemsPerPage}`
            ),
        getCrematoryById: id => axios.get(`/api/auth/locations/${id}`),
        postCrematory: values => {
            const body = new FormData();
            Object.keys(values).forEach(key => {
                body.append(key, values[key]);
            });

            return axios.post("/api/auth/locations", body, {
                headers: { "content-type": "multipart/form-data" }
            });
        },
        putUser: (userID, updatedFields) => {
            return axios.put(`api/auth/users/${userID}`, updatedFields);
        },
        putApplicationNotification: (applicationNotification) => {
            return axios.put(`api/auth/application-notification`, applicationNotification)
        },
        putCrematory: (crematoryID, updatedFields) => {
            if (updatedFields.logo) {
                const body = new FormData();
                Object.keys(updatedFields).forEach(key => {
                    body.append(key, updatedFields[key]);
                });

                return axios.put(`/api/auth/locations/${crematoryID}`, body, {
                    headers: { "content-type": "multipart/form-data" }
                });
            } else {
                return axios.put(
                    `/api/auth/locations/${crematoryID}`,
                    updatedFields
                );
            }
        },
        deleteCrematory: crematoryID => {
            return axios.delete(`/api/auth/locations/${crematoryID}`);
        },

        // *** Ceremony-related endpoints ***
        searchCeremony: (crematoryId, query) => {
            if (cancelCeremonySearch) {
                cancelCeremonySearch();
            }

            if (query === "") {
                return axios.get(
                    `/api/auth/locations/${crematoryId}/ceremonies?page_size=${itemsPerPage}`,
                    {
                        transformResponse: [transformCeremonyResponse],
                        cancelToken: new axios.CancelToken(function executor(
                            c
                        ) {
                            cancelCeremonySearch = c;
                        })
                    }
                );
            }

            return axios.get(
                `/api/auth/locations/${crematoryId}/ceremonies/search/${encodeURIComponent(
                    query
                )}?page_size=${itemsPerPage}`,
                {
                    transformResponse: [transformCeremonyResponse],
                    cancelToken: new axios.CancelToken(function executor(c) {
                        cancelCeremonySearch = c;
                    })
                }
            );
        },
        getCeremoniesPaginated: (crematoryID, pageNumber = 1) =>
            axios.get(
                `/api/auth/locations/${crematoryID}/ceremonies?page=${pageNumber}&page_size=${itemsPerPage}`,
                {
                    transformResponse: [transformCeremonyResponse]
                }
            ),
        getUserAssignedCeremonies: (pageNumber = 1) =>
            axios.get(
                `/api/auth/ceremonies?page=${pageNumber}&page_size=${itemsPerPage}`,
                {
                    transformResponse: [transformCeremonyResponse]
                }
            ),
        getUserExternalCeremonies: (pageNumber = 1) =>
            axios.get(
                `/api/auth/external-ceremonies/?page=${pageNumber}&page_size=${itemsPerPage}`,
                {
                    transformResponse: [transformCeremonyResponse]
                }
            ),
        getUserExternalCeremoniesCount: userId =>
            axios.get(`/api/auth/external-ceremonies/count`, {}),
        getCeremonyById: id =>
            axios.get(`/api/auth/ceremonies/${id}`, {
                transformResponse: [transformCeremonyResponse]
            }),
        getCeremoniesByRoom: (crematoryId, roomId, pageNumber = 1) =>
            axios.get(
                `/api/auth/locations/${crematoryId}/rooms/${roomId}/ceremonies?page=${pageNumber}&page_size=${itemsPerPage}`,
                {
                    transformResponse: [transformCeremonyResponse]
                }
            ),
        postCeremony: body =>
            axios.post("/api/auth/ceremonies", body, {
                transformResponse: [transformCeremonyResponse],
                transformRequest: [
                    transformCeremonyRequest,
                    ...axios.defaults.transformRequest
                ]
            }),
        putCeremony: (id, updatedFields) =>
            axios.put(`/api/auth/ceremonies/${id}`, updatedFields, {
                transformResponse: [transformCeremonyResponse],
                transformRequest: [
                    transformCeremonyRequest,
                    ...axios.defaults.transformRequest
                ]
            }),
        deleteCeremony: id => axios.delete(`/api/auth/ceremonies/${id}`),
        postRelatives: (ceremonyId, relatives) =>
            axios.post(
                `/api/auth/ceremonies/${ceremonyId}/relatives`,
                relatives,
                {
                    transformResponse: [transformCeremonyResponse]
                }
            ),
        deleteRelative: (ceremonyId, email) =>
            axios.delete(
                `/api/auth/ceremonies/${ceremonyId}/relatives?email=${email}`
            ),
        getCeremonyScript_RAW: ceremonyId =>
            // By default, axios parses response as JSON string.
            // We have to get a Blob response to be able to trigger a download
            fetch(`${baseURL}/api/auth/ceremonies/${ceremonyId}/script`, {
                headers: {
                    responseType: "blob",
                    Authorization: `Bearer ${getAuthToken().accessToken}`
                }
            }),
        unlockCeremony: (ceremonyId, time) =>
            axios.post(`/api/auth/ceremonies/${ceremonyId}/unlock`, { time }),

        // *** Room-related endpoints ***
        postRoom: (crematoryId, room) =>
            axios.post(`/api/auth/locations/${crematoryId}/rooms`, room),
        putRoom: room =>
            axios.put(
                `/api/auth/locations/${room.crematoryId}/rooms/${room.id}`,
                room
            ),
        deleteRoom: (crematoryId, roomId) =>
            axios.delete(`/api/auth/locations/${crematoryId}/rooms/${roomId}`),

        // *** Device-related endpoints ***
        postDevice: device =>
            axios.post(`/api/auth/rooms/${device.roomId}/device`, device),
        putDevice: device =>
            axios.put(
                `/api/auth/rooms/${device.roomId}/device/${device.id}`,
                device
            ),
        deleteDevice: (roomId, deviceId) =>
            axios.delete(`/api/auth/rooms/${roomId}/device/${deviceId}`),

        // *** Media-related endpoints ***
        getCeremonyAudioTracks: ceremonyId =>
            axios.get(`/api/auth/ceremonies/${ceremonyId}/music`),
        getCeremonyPhotos: id => axios.get(`/api/auth/ceremonies/${id}/photos`),
        getCeremonyVideos: id => axios.get(`/api/auth/ceremonies/${id}/videos`),
        postMedia: (ceremonyId, files, onProgress) => {
            const body = new FormData();
            Object.keys(files).forEach(key => {
                body.append(key, files[key]);
            });

            return axios.post(
                `${uploadURL}/api/auth/ceremonies/${ceremonyId}/upload`,
                body,
                {
                    headers: { "content-type": "multipart/form-data" },
                    onUploadProgress: onProgress ? onProgress : () => {}
                }
            );
        },
        postPhotoInBlock: (ceremonyId, blockId, files, onProgress) => {
            const body = new FormData();
            Object.keys(files).forEach(key => {
                body.append(key, files[key]);
            });

            return axios.post(
                `/api/auth/ceremonies/${ceremonyId}/upload/${blockId}`,
                body,
                {
                    headers: { "content-type": "multipart/form-data" },
                    onUploadProgress: onProgress ? onProgress : () => {}
                }
            );
        },
        postPhotoAndCrop: (ceremonyId, body, onProgress) => {
            return axios.post(
                `/api/auth/ceremonies/${ceremonyId}/crop-upload`,
                body,
                {
                    headers: { "content-type": "multipart/form-data" },
                    onUploadProgress: onProgress ? onProgress : () => {}
                }
            );
        },
        postPhotoAndRotate: (ceremonyId, body, onProgress) => {
            return axios.post(
                `/api/auth/ceremonies/${ceremonyId}/rotate-upload`,
                body,
                {
                    headers: { "content-type": "multipart/form-data" },
                    onUploadProgress: onProgress ? onProgress : () => {}
                }
            );
        },
        postProxy: urlPhoto => {
            return axios.post(`/api/auth/images/proxy`, { url: urlPhoto });
        },
        importAudioLibraryTrack: ({
            ceremonyId,
            trackToken,
            artist,
            title
        }) => {
            return axios.post(
                `/api/auth/ceremonies/${ceremonyId}/select-track/${trackToken}`,
                { artist, song: title }
            );
        },
        changeBackground: (ceremonyId, useBlurredPhotoBackground) => {
            return axios.patch(`/api/auth/ceremonies/${ceremonyId}`, {
                use_blurred_photo_background: useBlurredPhotoBackground
            });
        },
        // *** Block-related endpoints ***
        getCeremonyBlocks: ceremonyId =>
            axios.get(`/api/auth/ceremonies/${ceremonyId}/blocks`, {
                transformResponse: [transformBlockResponse]
            }),
        postBlock: (ceremonyId, block) =>
            axios.post(`/api/auth/ceremonies/${ceremonyId}/block`, block, {
                transformResponse: [transformBlockResponse],
                transformRequest: [
                    transformBlockRequest,
                    ...axios.defaults.transformRequest
                ]
            }),
        putBlock: (ceremonyId, block) =>
            axios.put(
                `/api/auth/ceremonies/${ceremonyId}/blocks/${block.id}`,
                block,
                {
                    transformResponse: [transformBlockResponse],
                    transformRequest: [
                        transformBlockRequest,
                        ...axios.defaults.transformRequest
                    ]
                }
            ),
        deleteBlock: (ceremonyId, blockId) =>
            axios.delete(
                `/api/auth/ceremonies/${ceremonyId}/blocks/${blockId}`
            ),
        deletePhoto: (ceremonyId, photoId) =>
            axios.delete(
                `/api/auth/ceremonies/${ceremonyId}/photos/${photoId}`
            ),
        reorderBlocks: (ceremonyId, positionArray) =>
            axios.post(
                `/api/auth/ceremonies/${ceremonyId}/blocks/reorder`,
                positionArray,
                {
                    transformResponse: [
                        transformReorderBlockResponse,
                        transformBlockResponse
                    ]
                }
            ),
        getBlockPreview: (ceremonyId, blockId, previewToken) =>
            // By default, axios parses response as JSON string.
            // We have to get a Blob response to be able to use URL.createObjectURL() method.
            fetch(
                `${baseURL}/api/auth/ceremonies/${ceremonyId}/block/${blockId}/preview/${previewToken}`,
                {
                    headers: {
                        responseType: "blob",
                        Authorization: `Bearer ${getAuthToken().accessToken}`
                    }
                }
            ),

        // *** Music library-related endpoints ***
        searchMusic: query => {
            if (cancelMusicLibrarySearch) {
                cancelMusicLibrarySearch();
            }

            return axios.get(
                `/api/auth/music-library/search/${encodeURIComponent(query)}`,
                {
                    cancelToken: new axios.CancelToken(function executor(c) {
                        cancelMusicLibrarySearch = c;
                    })
                }
            );
        },
        getOrganizations: () => axios.get(`/api/auth/all-organizations`)
    };
}
