import { useUpdateApplicationNotification } from "./useUpdateApplicationNotification";
import { ApplicationNotification } from "../types/ApplicationNotification";

export function useSaveApplicationNotification(
    applicationNotificationId: number,
    onSuccess?: (applicationNotification: ApplicationNotification) => void
) {
    const updateNotification = useUpdateApplicationNotification(
        applicationNotificationId
    );

    function handleNotificationSave(message: string, enabled: boolean) {
        updateNotification.mutate(
            {
                message: message,
                enabled: enabled,
            },
            {
                onSuccess: (data) => {
                    onSuccess(data.data);
                },
            }
        );
    }

    return {
        handleNotificationSave: handleNotificationSave,
        isLoading: updateNotification.isLoading,
    };
}
