import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routeNames from "../constants/routeNames";
import styles from "./PublicRoutes.module.scss";

import Navbar from "../components/Navbar";

import Login from "./views/Login";
import Logout from "./views/Logout";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import {ApplicationNotificationBar} from "../application/notification/ApplicationNotifcationBar";

const PublicRoutes = () => (
    <div className="hero is-fullheight">
        <div className="hero-head">
            <Navbar />
            <ApplicationNotificationBar />
        </div>
        <div className={`hero-body ${styles.container}`}>
            <Switch>
                <Route path={routeNames.login} component={Login} />
                <Route path={routeNames.logout} component={Logout} />
                <Route
                    path={routeNames.forgotPassword}
                    component={ForgotPassword}
                />
                <Route
                    path={routeNames.resetPassword}
                    component={ResetPassword}
                />

                <Route render={() => <Redirect to={routeNames.login} />} />
            </Switch>
        </div>
    </div>
);

export default PublicRoutes;
