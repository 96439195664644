import React from "react";
import { Link } from "react-router-dom";

import i18n from "../../constants/i18n";
import roles from "../../constants/roles";
import logoSrc from "../../assets/logo_text_white.svg";
import styles from "./Navbar.module.scss";
import ShowIf from "../ShowIf";
import { baseAssetURL } from "../../constants";

const NavbarView = ({
    isAdmin,
    isCrematoryAdmin,
    isImpersonation,
    extraLogo,
    username,
    showMenu,
    showAdminModal,
    stopImpersonation
}) => {
    return (
        <div className={`${styles.outerContainer}`}>
            <div
                className={`container ${styles.container} ${
                    extraLogo ? "has-custom-logo" : ""
                }`}
            >
                <ShowIf condition={Boolean(extraLogo)}>
                    <img
                        className={styles.extraLogo}
                        src={baseAssetURL + extraLogo}
                        alt="Crematory logo"
                    />
                </ShowIf>
                <a href="/">
                    <img
                        className={styles.novellaLogo}
                        src={logoSrc}
                        alt="Novella logo"
                    />
                </a>
                <ShowIf condition={Boolean(isImpersonation)}>
                    <button
                        className="button is-rounded is-secondary"
                        onClick={() => stopImpersonation()}
                    >
                        Stop als gebruiker
                    </button>
                </ShowIf>
                <ShowIf condition={Boolean(showMenu)}>
                    <div className="dropdown is-hoverable">
                        <div className="dropdown-trigger">
                            <button
                                className="button"
                                aria-haspopup="true"
                                aria-controls="main-menu"
                            >
                                <span>{username}</span>
                                <span className="icon is-small">
                                    <i
                                        className="fas fa-angle-down"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                            </button>
                        </div>
                        <div
                            className="dropdown-menu"
                            id="main-menu"
                            role="menu"
                        >
                            <div className="dropdown-content">
                                <ShowIf condition={isAdmin}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                            showAdminModal(roles.admin)
                                        }
                                    >
                                        {i18n.generic.manageAdminsLink}
                                    </a>
                                    <Link
                                        className="dropdown-item"
                                        to="/app/users/page/1"
                                    >
                                        {i18n.generic.manageUsersLink}
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/app/organizations"
                                    >
                                        {i18n.generic.manageOrganizationsLink}
                                    </Link>
                                    <Link
                                        className="dropdown-item"
                                        to="/app/admin/global-notification/edit"
                                        >{i18n.generic.manageGlobalNotification}
                                    </Link>
                                </ShowIf>
                                <ShowIf condition={isCrematoryAdmin}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                            showAdminModal(roles.crematory)
                                        }
                                    >
                                        {i18n.generic.manageAdminsLink}
                                    </a>
                                </ShowIf>
                                <Link
                                    className="dropdown-item"
                                    to="/app/password-reset/1"
                                >
                                    {i18n.generic.changePasswordLink}
                                </Link>
                                <Link className="dropdown-item" to="/logout">
                                    {i18n.generic.logout}
                                </Link>
                            </div>
                        </div>
                    </div>
                </ShowIf>
            </div>
        </div>
    );
};

export default NavbarView;
