const translations = {
    adminManagementModal: {
        description:
            "U kunt hier uw collega’s toevoegen. Zij kunnen vervolgens ook locaties en plechtigheden beheren.",
        title: "Beheerder(s)"
    },
    blockEditModal: {
        durationFieldLabel: "Tijdsduur (min)",
        durationFieldPlaceholder: "Tijdsduur (min)",
        noPhotosWarning: `Er zijn geen foto's gekoppeld aan dit blok.`,
        photoInterval: "Interval tussen de afbeeldingen: <INTERVAL> seconde(n)",
        submitButtonLabel: "Opslaan",
        title: `Foto's tijdens`
    },
    blockProcessingModal: {
        bodyTitle: "Voorvertoning aan het genereren…",
        footerButtonLabel: "Bekijk voorvertoning",
        title: "Voorvertoning"
    },
    blockTypeModal: {
        musicType: "Muziek",
        photoType: "Afbeeldingen",
        speechType: "Spreker",
        titleCreateMode: "Blok toevoegen",
        titleEditMode: "Instellingen",
        videoType: "Video"
    },
    ceremonyModal: {
        allowedPhotosOnSpeechBlockLabel: `Maximum aantal foto's toegestaan tijdens een sprekersblok`,
        allowedPhotosOnSpeechBlockPlaceholder: "1 afbeelding",
        ceremonyMasterLabel: "Coördinator / Begeleider",
        crematoryLabel: "Locatie",
        typeLabel: "Type",
        crematoryPlaceholder: "Selecteer een locatie",
        dateLabel: "Datum",
        deleteButtonLabel: "Definitief verwijderen",
        durationLabel: "Duur plechtigheid",
        durationPlaceholder: "Duur plechtigheid",
        familyNameLabel: "Naam",
        familyNamePlaceholder: "Familienaam",
        roomLabel: "Ruimte",
        roomPlaceholder: "Selecteer een ruimte",
        serviceCodeLabel: "Dienstcode/dossiernummer",
        titleCreateMode: "Plechtigheid aanmaken",
        titleEditMode: "Plechtigheid wijzigen",
        waitingTimeLabel: "Verwachte inlooptijd",
        waitingTimePlaceholder: "Verwachte inlooptijd",
        streamingCeremonyMovedToAnotherRoomNotice:
            "De ruimte waar u de plechtigheid naartoe probeert te verplaatsen heeft geen livestream mogelijkheid. Verplaats de plechtigheid naar een andere ruimte of zet voor deze plechtigheid de livestream uit.",
        streamingCeremonyDisabled:
            "U schakelt streaming uit voor een plechtigheid. Vergeet niet om deze plechtigheid in PCI-Webcast te verwijderen."
    },
    ceremonySuccessModal: {
        toOverview: "Naar overzicht",
        toTimeline: "Naar tijdlijn"
    },
    ceremonyOverview: {
        roomFilterPlaceholder: "Alle ruimtes",
        tableItemNoRoom: "Geen ruimte",
        tableItemOpenButtonLabel: "Bewerken"
    },
    ceremonyType: {
        Ceremony: "Plechtigheid",
        Condolence: "Condoleance"
    },
    changePasswordScreen: {
        goBack: "Ga terug",
        title: "Wijzig wachtwoord"
    },
    crematoryModal: {
        activate: "Activeer",
        adminEmailFieldLabel:
            "Emailadres Beheerder (geen info@mail.nl gebruiken)",
        adminNameFieldLabel: "Naam Beheerder",
        deactivate: "Deactiveer",
        nameFieldPlaceholder: "Naam onderneming",
        titleCreateMode: "Locatie toevoegen",
        titleEditMode: "Instellingen",
        locationEmail: "Locatie email",
        locationPhoneNumber: "Locatie telefoonnummer",
        organizationLabel: "Organisatie (optioneel)",
        organizationPlaceholder: "Geen"
    },
    photos: {
        blackBackground: "Zwarte achtergrond",
        blurredBackground: "Foto achtergrond"
    },
    organizationUserManagementModal: {
        title: "Gebruiker aanmaken",
        description:
            "Hier kunt u een gebruiker aanmaken en deze verbinden met een locatie",
        location: "Locatie",
        locationPlaceholder: "Selecteer een locatie"
    },
    crematoryOverview: {
        tableItemOpenButtonLabel: "Bekijk"
    },
    deactivatedCrematoryModal: {
        body:
            "Dit account is op gedeactiveerd. Neem contact op met Hecla Professional voor re-activatie op telefoonnummer: 074-2480800.",
        footerButtonLabel: "Uitloggen",
        title: "Inactief account"
    },
    deviceModal: {
        titleCreateMode: "Apparaat toevoegen",
        titleEditMode: "Instellingen",
        deviceTypeLabel: "Player type",
        deviceTypePlaceholder: "Player type"
    },
    deviceOverview: {
        addDevice: "Nieuw apparaat",
        deviceIdLabel: "Player ID",
        deviceTypeLabel: "Player type",
        notAssignedToRoom: "Not assigned to a room",
        registrationCode: "Registratie code",
        unknownDeviceType: "Onbekend",
        roomIdLabel: "Ruimte ID"
    },
    durationWarningModal: {
        buttonLabel: "Doorgaan",
        title: "Blok toevoegen",
        warningBody:
            "U overschrijdt de tijd waarvoor u de locatie hebt gereserveerd",
        warningTitle: "Let op!"
    },
    footer: {
        privacyStatement: "Privacy Statement",
        privacyStatementUrl: "https://www.pcinederland.nl/privacyverklaring/",
        poweredBy: "Product of PCI Nederland, Business Unit: AV"
    },
    forgotPasswordForm: {
        emailLabel: "Uw emailadres",
        reset: "Resetten",
        sent: "Verstuurd"
    },
    generic: {
        add: "Toevoegen",
        addClient: "Locatie toevoegen",
        addMusicFileSuccess: "Uw muziekbestand is succesvol toegevoegd",
        address: "Adres",
        addVideoFileSuccess: "Uw videobestand is succesvol toegevoegd",
        aspectRatio: "Beeldverhouding",
        backToOverview: "Terug naar overzicht",
        ceremonyOverview: "Plechtigheden overzicht",
        changePasswordLink: "Wachtwoord resetten",
        location: "Locatie",
        externalCeremoniesLink: "Externe plechtigheden",
        city: "Plaats",
        clientOverview: "Locatie overzicht",
        clientPlural: "locaties",
        clientSingular: "locatie",
        deleteButtonLabel: "Definitief verwijderen",
        eventSingular: "plechtigheid",
        eventPlural: "plechtigheden",
        create: "Aanmaken",
        createBlockSuccess:
            "Het blok is succesvol toegevoegd, u kunt nu foto's slepen naar het blok",
        createVideoBlockSuccess: "Het blok is succesvol toegevoegd",
        createCeremonySuccess: "De plechtigheid is succesvol toegevoegd",
        createCrematorySuccess: "De locatie is succesvol toegevoegd",
        createDeviceSuccess: "Het apparaat is succesvol toegevoegd",
        createOrganizationSuccess: "De organisatie is succesvol toegevoegd",
        createRoomSuccess: "De ruimte is succesvol toegevoegd",
        delete: "Verwijderen",
        show: "Bekijken",
        deleteItemConfirmationPrompt: "Weet u zeker dat u wilt verwijderen?",
        deviceManagement: "Apparaten beheren",
        downloadScript: "Draaiboek printen",
        edit: "Bewerken",
        email: "Email",
        error:
            "Er heeft een fout plaatsgevonden. Vernieuw de pagina alstublieft.",
        failedToLoadData: "data ophalen is mislukt",
        goBackToOverview: "Terug naar overzicht",
        liveStreaming: "Livestreaming",
        logo: "Logo",
        logout: "Uitloggen",
        mainImage: "Hoofdfoto",
        manageAdminsLink: "Beheerders",
        manageUsersLink: "Gebruikers",
        manageOrganizationsLink: "Organisaties",
        manageGlobalNotification: "Systeem melding",
        name: "Naam",
        noImageSelectedWarning:
            "Er zijn geen foto’s gekoppeld aan dit blok. Indien geselecteerd word de hoofdfoto weergegeven, anders zal er bij dit blok geen foto weergegeven worden.",
        noResultsFound: "Geen resultaten gevonden",
        notYetVerified: "Nog niet geactiveerd",
        phoneNumber: "Telefoonnummer",
        photoCrop: "Bijsnijden",
        recording: "Opnemen",
        region: "Provincie",
        zipcode: "Postcode",
        room: "Ruimte",
        roomManagement: "Ruimte beheren",
        externalCeremonies: "Externe ceremonies",
        roomNumber:
            "Player ID (begint altijd bij 1 en correspondeert met het aula nummer van het besturingssysteem)",
        rooms: "Ruimtes",
        save: "Opslaan",
        script: "Draaiboek",
        searchClients: "Locatie zoeken",
        searchOrganizations: "Organisaties zoeken",
        searchUsers: "Gebruiker zoeken",
        searchCeremonies: "Plechtigheid zoeken",
        totalCrematoryCount: "Aantal locaties: ",
        totalOrganizationCount: "Aantal organisaties: ",
        totalUserCount: "Aantal gebruikers: ",
        totalDataUsage: "Totale dataverbruik: ",
        unlock: "Ontgrendelen",
        unlockCeremonySuccess: "Tijdlijn zijn succesvol ontgrendeld",
        updateCeremonySuccess: "Plechtigheidgegevens zijn succesvol bijgewerkt",
        updateAdminsSuccess: "Beheerders zijn succesvol bijgewerkt",
        updateCrematorySuccess: "De locatie is succesvol bijgewerkt",
        updateUserSuccess: "De gebruiker is succesvol bijgewerkt",
        updatedBlockSuccess: "Het blok is succesvol bijgewerkt",
        updatedImage: "Huidige afbeelding wordt aangepast",
        newImage: "Extra afbeelding wordt aangemaakt",
        updateOrganizationSuccess: "De organisatie is succesvol bijgewerkt",
        updateRoomSuccess: "De ruimte is succesvol bijgewerkt",
        webcastOrganizationId: "PCI-Webcast organisatie ID",
        signage: "Bewegwijzering",
        inCollaborationWith: "Begeleid door:",
        processingFailureMessage:
            'Het is niet gelukt om "<FILENAME>" om te zetten, pas het bronbestand aan en upload opnieuw.',
        blurredBackground: "Foto opvulling",
        cancel: "Annuleren",
        success: "Succes"
    },
    lockedTimelineModal: {
        ceremonyLockedParagraph1:
            "De periode om de plechtigheid te bewerken is verstreken.",
        ceremonyLockedParagraph2:
            "Bij vragen verzoeken wij u contact op te nemen met de locatie.",
        mediaAlreadyClearedUpExplanationParagraph1:
            "De media bestanden zijn omwille van privacy redenen verwijderd",
        mediaAlreadyClearedUpExplanationParagraph2: "",
        ceremonyMasterContactHeader: "Uw coördinator of begeleider",
        title: "Bewerken gesloten"
    },
    loginForm: {
        emailLabel: "Uw email",
        passwordLabel: "Wachtwoord",
        submitButtonLabel: "inloggen",
        termsAndConditionsAgreement: "Ik ga akkoord met de algemene voorwaarden"
    },
    mainCeremonyPhotoModal: {
        body:
            "Deze afbeelding wordt weergeven bij sprekers- en muziekblokken waar geen afbeeldingen zijn ingesteld. Wanneer u in dit veld een afbeelding of foto toevoegd en het blok leeg laat zal deze weergegeven worden tijdens een blok.",
        footerButtonLabel: "Kies Afbeelding",
        title: "Stel een standaard foto in",
        noPhotosUploaded: `Geen foto's gevonden. Upload eerst een foto.`,
        removeMainImageLabel: "Reset hoofdafbeelding"
    },
    musicUploadModal: {
        title: "Muziekbestand(en)",
        uploadFieldLabel: "Upload hier uw eigen muziek",
        searchFieldPlaceholder: "Zoeken binnen de muziek database",
        validationOneOfFields:
            "Selecteer een muziekstuk uit de bibliotheek of upload uw eigen muziek",
        noResultsWarning: "Uw zoekopdracht heeft geen resultaten opgeleverd"
    },
    organizationModal: {
        titleCreateMode: "Organisatie toevoegen",
        titleEditMode: "Organisatie wijzigen",
        webcastOrganizationIdInfo:
            "Momenteel is er geen automatische organisatie koppeling met PCI-Webcast mogelijk. U kunt deze handmatig aanmaken en de ID hier invullen."
    },
    organizationOverview: {
        title: "Organisaties overzicht",
        showOrganization: "Toon"
    },
    photoADjustmentModal: {
        title: "Foto bijwerken",
        rotateTitle: "Foto roteren",
        cropTitle: "Foto bijsnijden",
        saveAndReturnToBlockEdit: "Opslaan en bewerking verlaten",
        cropAndReturnToBlockEdit: "Bijsnijden en bewerking verlaten",
        updateCurrentImage: "Huidige foto bijwerken",
        addAsNewImage: "Als nieuwe foto toevoegen",
        imageInBlockWarning:
            "Deze foto is gekoppeld aan een of meerdere blokken. Indien u kiest voor ",
        imageInBlockWarning2:
            " zullen deze ook worden vervangen. Wilt u dit niet? kies dan voor "
    },

    photoUploadModal: {
        title: `Upload hier uw foto's`,
        uploadFieldLabel: "Fotobestand(en)",
        fileSizeLimitWarning:
            "Een of meerdere geselecteerde bestanden overschrijden de bestandslimiet van 30 MB. Verklein de afbeelding(en) en probeer het opnieuw.",
        imageResolutionWarning:
            "Een of meerdere geselecteerde bestanden overschrijden de maximale resolutie van 80 megapixel. Verklein de afbeelding(en) en probeer het opnieuw.",
        fileLimitWarning:
            "U mag niet meer dan 100 foto's per keer uploaden binnen een muziek blok",
        speakerFileLimitWarning:
            "U overschrijdt het maximum aantal foto's die aan een sprekersblok gekoppeld kunnen worden"
    },
    previewModal: {
        title: "Voorvertoning",
        previous: "Vorige",
        next: "Volgende"
    },
    relativesModal: {
        fieldLabel: "Genodigden",
        fieldPlaceholder: "Email",
        nameFieldPlaceholder: "Naam",
        submitButtonLabel: "Aanmaken",
        saveLabel: "Opslaan",
        title: "Assistent uitnodigen"
    },
    resetPasswordForm: {
        successMessage: "Het wachtwoord is succesvol bijgewerkt"
    },
    roomModal: {
        titleCreateMode: "Ruimte toevoegen",
        titleEditMode: "Instellingen",
        masterUnitLabel: "Master Unit",
        typeLabel: "Type",
        signageLabel: "Bewegwijzering",
        signageEnabledLabel: "Actief"
    },
    roomOverview: {
        addNewRoomButtonLabel: "Nieuwe ruimte",
        roomOverview: "Ruimte overzicht",
        masterUnitLabel: "Master Unit"
    },
    screenSizeModal: {
        body:
            "Helaas wordt App niet ondersteund op dit apparaat. Wij adviseren u gebruik te maken van een Tablet, Laptop of PC",
        title: "Uw schermresolutie is te klein"
    },
    speechModal: {
        durationField: "Geschatte tijdsduur (in minuten)",
        title: "Spreker toevoegen",
        speakerName: "Spreker naam"
    },
    timelineOverview: {
        attachPhotoToBlockSuccess: `Uw foto's zijn succesvol gekoppeld`,
        blocksModifiedSinceLastUpdate: `Er zijn blokken verwijderd/toegevoegd sinds uw laatste wijziging. De laatste wijzigingen worden nu opgehaald.`,
        photoDeleted: `Foto verwijderd`,
        exceededSpeechBlockPhotoLimit: `U heeft het maximum aantal foto's bereikt die aan dit blok gekoppeld kunnen worden`,
        multiDragSingluarSpeechBlockPhotoLimit: `Aan dit blok kunt u maar 1 foto toevoegen`,
        multiDragPluralSpeechBlockPhotoLimit: `Aan dit blok kunt u maar <NUMBER> foto's toevoegen`,
        fallbackImageLinkTooltip: "Hoofdfoto instellen",
        preview: "Voorvertoning",
        relativesInvitationButtonLabel: "Genodigden",
        startTutorial: "Help",
        timeline: "Tijdlijn:",
        photoInBlockError:
            "Deze foto kan niet worden verwijderd omdat deze nog in een blok zit en/of is geselecteerd als hoofdfoto. Verwijder de foto uit het blok en/of kies een andere hoofdfoto en probeer het opnieuw.",
        blurredBackgroundCheckboxLabel: "Onscherpe achtergrond",
        mainPicture: "Hoofdfoto",
        selectMainPicture: "Selecteer Hoofdfoto",
        moveBlocksSuccess: "Uw blokken zijn succesvol verplaatst"
    },
    tutorial: {
        finish: "Beginnen",
        nextStep: "Volgende stap",
        stepUploadingPhotos: "Hier kunt u uw eigen foto’s uploaden.",
        stepTimelineOverview:
            "In deze tijdlijn ziet u een overzicht in blokken van alle momenten tijdens de plechtigheid die elkaar in volgorde opvolgen.",
        stepAddingBlocks:
            "In de Tijdlijn kunt u muziekstukken of sprekers eenvoudig toevoegen met de “+” knop.",
        stepDraggingPhotosToBlock: `U kunt eerder geïmporteerde foto's naar een sprekersblok verslepen of koppelen aan een muziekstuk (audio) om toe te voegen aan het moment.`,
        stepFallbackImage: `Een hoofdfoto instellen. De geselecteerde hoofdfoto zal getoond worden tussen elk blok. Tevens zal deze weergegeven worden bij sprekersblokken waaraan geen foto's gekoppeld zijn.`,
        stepHelp:
            "Door middel van deze knop kunt u deze uitleg opnieuw starten om zo de functies binnen de website opnieuw weer te geven.",
        stepScript: "Klik hier om het draaiboek te downloaden als pdf",
        stepRelatives: "Klik hier om personen uit te nodigen voor assistentie",
        stepBlockPreview: "Klik hier om de voorvertoning te bekijken",
        tooltipTitle: "Uitleg",
        stepBlurredBackground:
            "Door middel van deze knop kunt u er voor kiezen om de achtergrond van fotos welke niet groot genoeg zijn voor het scherm beeldvullend te maken met een vervaagde achtergrond van dezelfde foto. Indien dit uit staat zal de opvulling van het scherm zwart zijn."
    },
    unlockCeremonyModal: {
        dropdownLabel: "Duur",
        dropdownPlaceholder: "Selecteer tijd",
        title: "Ontgrendelen"
    },
    uploadField: {
        multipleFiles: "Diverse bestanden"
    },
    userOverview: {
        impersonateConfirmationPrompt: "Voordoen als gebruiker: <NAME>?",
        impersonateSuccess: "Je doet je voor als gebruiker: <NAME>",
        stopImpersonateConfirmationPrompt: "Stop als gebruiker: <NAME>?",
        stopImpersonateSuccess: "Voordoen als gebruiker <NAME> is gestopt.",
        title: "Gebruikers overzicht",
        tableItemImpersonateButtonLabel: "Voordoen als gebruiker",
        showUser: "Toon"
    },
    editUser: {
        locationLabel: "Locatie",
        roleLabel: "Rol",
        nameLabel: "Naam",
        phoneLabel: "Telefoon",
        organizationLabel: "Organisatie",
        backToUserButton: "Terug naar gebruiker",
        updatedAtLabel: "Voor het laatst gewijzigd",
        createdAtLabel: "Aangemaakt op",
        idLabel: "ID",
        editButton: "Aanpassen",
        backToUserOverviewButton: "Terug naar gebruikersoverzicht",
        emailLabel: "Email",
        userLabel: "Gebruiker"
    },
    globalNotification: {
        title: "Notificatie aanpassen",
        summary:
            "Bij het inschakelen van de notificatie wordt deze getoond met een gele achtergrond op alle pagina's onder de top navigatie.",
        body: "Tekst",
        message: "Bericht",
        enabled: "Ingeschakeld",
        saved: "Opgeslagen",
        save: "opslaan"
    },
    roles: {
        Admin: "Admin",
        Crematorium: "Locatie beheerder",
        Organization: "Beheerder van meerdere locaties",
        EndUser: "Eindgebruiker"
    },
    validation: {
        addAtLeastOneRoom: "Voeg ten minste één ruimte toe",
        email: "Het e-mail adres is ongeldig",
        fileType: "Dit bestandsformaat wordt niet ondersteund",
        fileLimit: "Selecteer maximaal 200 afbeeldingen",
        matchPasswords: "Het wachtwoord komt niet overeen",
        maxUploadFileSize: "Bestand mag niet groter zijn dan 500MB",
        number: "Het ingevoerde nummer is ongeldig",
        phoneNumber: "Het ingevoerde telefoonnummer is ongeldig",
        roomNumber: "Het ingevoerde ruimtenummer is ongeldig",
        required: "Verplicht",
        alphanumeric: "Dit veld moet alfanumeriek zijn",
        length20: "Dit veld mag maximaal 20 karakters bevatten"
    },
    processingFailure: {
        deviceGenerationError:
            "Er is iets fout gegaan. Er is maximaal 1 apparaat per ruimte toegestaan. Player ID wordt gebruikt voor het genereren van de ruimte locatie codes. Deze moet uniek zijn.",
        roomDeleteError:
            "Er is iets fout gegaan. Zorg ervoor dat er geen devices meer gekoppeld zijn aan deze ruimte en dat de diensten binnen deze ruimte zijn verwijderd."
    },
    videoUploadModal: {
        title: "Video toevoegen",
        uploadFieldLabel: "Video"
    },
    organizationDetail: {
        idLabel: "ID",
        nameLabel: "Naam",
        emailLabel: "Email",
        title: "Organisatie",
        name: "Naam",
        address: "Adres",
        postalCode: "Postcode",
        city: "Plaats",
        country: "Land",
        email: "Email",
        phone: "Telefoon",
        website: "Website",
        organizationLabel: "Organisatie",
        backToOrganizationsOverview: "Terug naar overzicht",
        editButton: "Aanpassen",
        webcastExternalId: "Webcast ID",
        zipCode: "Postcode",
        locations: "Locaties",
        users: "Gebruikers"
    },
    blockAmountWarningModal: {
        warningTitle: "Let op!",
        warningBody:
            "U heeft het maximum aantal blokken bereikt die aan een plechtigheid gekoppeld kunnen worden. Verwijder een blok indien u een nieuw blok wilt toevoegen.",
        buttonLabel: "Ga terug",
        title: "Blok toevoegen"
    },
    deleteWarningModal: {
        warningTitle: "Let op!",
        warningBody:
            "U verwijderd alle foto’s, muziek en video’s en evt. livestream. Weet u het zeker? U kunt dit niet ongedaan maken.",
        backButtonLabel: "Ga terug",
        deleteButtonLabel: "Definitief verwijderen",
        title: "Ceremonie verwijderen",
        iAmSure: "Ja ik weet het zeker"
    },
    blockComment: {
        title: "Opmerkingen",
        addButton: "Voeg opmerking toe",
        placeholder: "Voeg een opmerking toe",
        saved: "Opmerking opgeslagen",
        deleteConfirmation:
            "Weet u zeker dat u deze opmerking wilt verwijderen?",
        deleted: "Opmerking verwijderd"
    }
};

export default translations;
