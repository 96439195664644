import React, { PropsWithChildren, useRef } from "react";
import { EventEmitter } from "events";
import { EventContext } from "./EventContext";

export function EventContextProvider(props: PropsWithChildren<unknown>) {
    const eventEmitter = useRef<EventEmitter>();

    if (!eventEmitter.current) {
        eventEmitter.current = new EventEmitter();
    }

    return (
        <EventContext.Provider value={eventEmitter.current}>
            {props.children}
        </EventContext.Provider>
    );
}
