import React, { useEffect, useState } from "react";
import styles from "./ApplicationNotificationForm.module.scss";
import i18n from "../../constants/i18n";
import { showToast } from "../../actions/toasts";
import { ApplicationNotification } from "./types/ApplicationNotification";
import { useDispatch } from "react-redux";
import { useSaveApplicationNotification } from "./hooks/useSaveApplicationNotification";
import { useEventEmitter } from "../../core/events/useEventEmitter";
import { LocalEvents } from "../../core/events/LocalEvents";

interface ApplicationNotificationInputProps {
    notification: ApplicationNotification;
}

export function ApplicationNotificationForm(
    props: ApplicationNotificationInputProps
) {
    const dispatch = useDispatch();
    const [content, setContent] = useState<string | null>(
        props.notification.message || null
    );
    const [enabled, setEnabled] = useState<boolean | null>(
        props.notification.enabled || null
    );
    const saveApplicationNotification = useSaveApplicationNotification(
        1,
        onApplicationNotificationSaved
    );
    const eventEmitter = useEventEmitter();

    async function onApplicationNotificationSaved() {
        dispatch(
            showToast({
                body: i18n.globalNotification.saved,
                title: i18n.generic.success,
                themeClass: "is-success",
                preventDismiss: false,
            })
        );
        eventEmitter.emit(LocalEvents.APPLICATION_NOTIFICATION_CHANGED);
    }

    useEffect(() => {
        setContent(props.notification.message);
    }, [props.notification.message]);

    useEffect(() => {
        setEnabled(props.notification.enabled);
    }, [props.notification.enabled]);

    return (
        <div>
            <div className={`${styles.field}`}>
                <label>{i18n.globalNotification.message}</label>
                <div className="control has-icons-right">
                    <textarea
                        className={`${styles.textarea}`}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        required={true}
                    />
                </div>
            </div>

            <div className={`${styles.field}`}>
                <label>{i18n.globalNotification.enabled}</label>
                <input
                    type="checkbox"
                    className={`${styles.checkboxInput}`}
                    checked={enabled}
                    onChange={(e) => setEnabled(e.target.checked)}
                />
            </div>

            <div className={`${styles.field}`}>
                <button
                    onClick={() => {
                        setContent(content?.trim());
                        saveApplicationNotification.handleNotificationSave(
                            content,
                            enabled
                        );
                    }}
                    className="button is-primary is-rounded is-small"
                >
                    {i18n.generic.save}
                </button>
            </div>
        </div>
    );
}
